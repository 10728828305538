{
    "status": 200,
    "error_message": "",
    "result": {
        "publish_info": {
            "publish_id": 12093487,
            "type_info": 0,
            "price": 22500000,
            "zipcode": "599-0000",
            "address": "大阪府堺市東区南町1-9-3,",
            "address_2": "東畑",
            "address_3": "3",
            "built_yyyymm": "1978年7月",
            "building_size": 189.27,
            "land_size": 245.90,
            "size": null,
            "floor": null,
            "station_name_ja": "JR海南駅",
            "station_minutes": 67,
            "address_text": "和歌山県海南市",
            "layout_text": "2LDK",
            "owner": {
                "user": {
                    "user_id": 10000000027,
                    "nickname": "猫好き",
                    "sei": "斉藤",
                    "mei": "新",
                    "email": "yukiko.kawaguchi+4@propre-japan.com",
                    "tel": "0467111111",
                    "intro": "変更確認てすと",
                    "avatar_image_url": "dummy/user1.jpg"
                },
                "identification_status_info": {
                    "process_status": 2,
                    "comment": null
                },
                "posts_count": 30,
                "is_ban": false
            },
            "agent": {
                "user": {
                    "user_id": 10000000180,
                    "nickname": "ABC不動産",
                    "sei": null,
                    "mei": null,
                    "email": "yukiko.kawaguchi+140@propre-japan.com",
                    "tel": "0467-33-3333",
                    "intro": "エージェント140の紹介文です。usersテーブル",
                    "avatar_image_url": "dummy/user2.jpg"
                },
                "identification_status_info": {
                    "process_status": 0,
                    "comment": null
                },
                "posts_count": 0,
                "is_ban": false
            },
            "maximumOfferAmount": 38000000,
            "image_url": "dummy/house2.jpg",
            "additional_info": {
                "build_stricture_text": "木造",
                "use_districts_text": "第一種低層住居専用地域"
            },
            "refusal_count": 4,
            "un_read_refusal_count": 1
        }
    }
}