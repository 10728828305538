<h1 class="text-primary mb-4">物件詳細</h1>

@if (publishData?.result?.publish_info?.agent == null && publishData?.result?.publish_info?.not_found_agent_announce_log_count ?? 0 > 0) {
  <div class="property-announce">
    <div class="material-symbols-rounded property-announce__icon" aria-hidden="true">report</div>
    <div class="property-announce__text">内覧希望・オファーから24時間経過して担当エージェントが未定です</div>
  </div>
}

<div class="report-button-wrapper">

  @let publish_info_refusal_count = publishData?.result?.publish_info?.refusal_count ?? 0;
  @let publish_info_un_read_refusal_count = publishData?.result?.publish_info?.un_read_refusal_count ?? 0;

  @if (publish_info_refusal_count === 0) {
    <!-- 違反報告ない時 -->
    <div><button type="button" class="btn btn-secondary" disabled>違反報告(0)</button></div>
  }
  @else if (publish_info_un_read_refusal_count === 0) {
    <!-- 違反報告全部確認済み -->
    <div>
      <button type="button" class="btn btn-secondary" (click)="openReportModal()">
        違反報告({{ publish_info_refusal_count }})
      </button>
    </div>
  }
  @else {
    <!-- 未確認の違反報告あり -->
    <div>
      <button type="button" class="btn btn-danger" (click)="openReportModal()">
        違反報告({{ publish_info_refusal_count }})
      </button>
    </div>
    <div class="report-button-text fw-bold fs-2 text-danger">未確認の違反報告があります</div>
  }
</div>

<div class="container-fluid px-0">
  <div class="row">

    <div class="col-8">
      <div class="card">
        <div class="card-body p-4">

          <div class="d-flex align-items-baseline">
            <h2 class="pe-3 mb-0">物件内容</h2>
            <div class="fs-6 ps-3">物件ID：<span class="fw-bold fs-4">{{ publishData?.result?.publish_info?.publish_id }}</span></div>
          </div>

          <div class="d-flex align-items-stretch mt-4">

            <div class="property-detail-left">

              <div class="property-photo">
                <div class="property-photo__image-wrapper">
                  <img src="{{ publishData?.result?.publish_info?.image_url }}" alt="物件写真" class="property-photo__image">
                </div>
                @if(publishData?.result?.publish_info?.type_info === 0) {
                  <div class="property-photo__type">マンション</div>
                }
                @else if (publishData?.result?.publish_info?.type_info === 1) {
                  <div class="property-photo__type">土地</div>
                }
                @else if (publishData?.result?.publish_info?.type_info === 2) {
                  <div class="property-photo__type">戸建</div>
                }
                @else if (publishData?.result?.publish_info?.type_info === 3) {
                  <div class="property-photo__type">投資物件</div>
                }
              </div>

              <div class="mt-4 d-flex align-items-center">
                <div class="user-icon-wrapper">
                  <img src="{{ publishData?.result?.publish_info?.owner?.user?.avatar_image_url ?? 'assets/images/user-default.svg' }}" alt="プロフィール画像" class="user-icon-image">
                  @if (publishData?.result?.publish_info?.owner?.identification_status_info?.process_status == 2) {
                    <img src="yellow-check-circle.png" alt="本人確認済み" class="user-verified-image">
                  }
                </div>
                <div class="ps-4">
                  <div class="fs-6 text-muted mb-1">オーナー</div>
                  <div class="d-flex align-items-baseline">
                    <a [routerLink]="['/user', publishData?.result?.publish_info?.owner?.user?.user_id]" class="d-block fs-3 fw-bold">{{ publishData?.result?.publish_info?.owner?.user?.nickname }}</a>
                    <span class="ms-3">{{ publishData?.result?.publish_info?.owner?.user?.sei }} {{ publishData?.result?.publish_info?.owner?.user?.mei }}</span>
                  </div>
                </div>
              </div>

              @if (publishData?.result?.publish_info?.agent != null) {
                <div class="mt-4 d-flex align-items-center">
                  <div class="user-icon-wrapper">
                    <img src="{{ publishData?.result?.publish_info?.agent?.user?.avatar_image_url ?? 'assets/images/user-default.svg' }}" alt="プロフィール画像" class="user-icon-image">
                  </div>
                  <div class="ps-4">
                    <div class="fs-6 text-muted mb-1">担当エージェント</div>
                    <div class="d-flex align-items-baseline">
                      <span class="d-block fs-3 fw-bold">{{ publishData?.result?.publish_info?.agent?.user?.nickname }}</span>
                    </div>
                  </div>
                </div>
              }
              @else {
                <div class="mt-4 property-no-agent">
                  <p class="mb-0">担当エージェントは決まっていません。</p>
                </div>
              }
            </div>

            <div class="property-detail-right">

              <table class="table table-sm table-borderless property-table">
                <tbody>
                  <tr>
                    <th>所在地</th>
                    <td>
                      @let zipcode = publishData?.result?.publish_info?.zipcode;
                      @if (zipcode) {
                        &#12306; {{ zipcode | zipcode }}<br/>
                      }
                      {{ publishData?.result?.publish_info?.address_text }}{{ publishData?.result?.publish_info?.address_2 }}{{ publishData?.result?.publish_info?.address_3 }}
                    </td>
                  </tr>
                  <tr>
                    <th>建物面積</th>
                    <td>
                      @if (publishData?.result?.publish_info?.building_size) {
                        {{ publishData?.result?.publish_info?.building_size }}㎡
                      }
                      @else {
                        -
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>土地面積</th>
                    <td>
                      @if (publishData?.result?.publish_info?.land_size) {
                        {{ publishData?.result?.publish_info?.land_size }}㎡
                      }
                      @else {
                        -
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>築年月</th>
                    <td>{{ publishData?.result?.publish_info?.built_yyyymm ?? '-' }}</td>
                  </tr>
                  <tr>
                    <th>間取り</th>
                    <td>{{ publishData?.result?.publish_info?.layout_text ?? '-' }}</td>
                  </tr>
                  <tr>
                    <th>構造</th>
                    <td>{{ publishData?.result?.publish_info?.additional_info?.build_stricture_text ?? '-' }}</td>
                  </tr>
                  <tr>
                    <th>用途地域</th>
                    <td>{{ publishData?.result?.publish_info?.additional_info?.use_districts_text ?? '-' }}</td>
                  </tr>
                  <tr>
                    <th>最寄駅</th>
                    <td>
                      {{ publishData?.result?.publish_info?.station_name_ja ?? '-' }}
                      @if (publishData?.result?.publish_info?.station_name_ja != null) {
                        徒歩{{ publishData?.result?.publish_info?.station_minutes }}分
                      }
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="border-top pt-2">
                <table class="table table-borderless property-table property-price-table">
                  <tbody>
                    <tr>
                      <th>売却希望価格</th>
                      <td class="fw-bold">
                        <span class="fs-1 pe-1">{{ publishData?.result?.publish_info?.price | number }}</span>円
                      </td>
                    </tr>
                    <tr>
                      <th>最高オファー額</th>
                      <td class="fw-bold text-danger">
                        <span class="fs-1 pe-1">{{ publishData?.result?.publish_info?.maximumOfferAmount | number }}</span>円
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="card h-100">
        <div class="card-body p-4 position-relative h-100">

          <h2 class="pe-3 mb-0">コメント</h2>

          <div class="property-comment-wrapper position-absolute">

            @for (item of commentListData; track $index) {
              <div class="property-comment">
                <div class="property-comment__user-icon">
                  <div class="user-icon-wrapper is-small">
                    <img src="{{ item.owner?.user?.avatar_image_url ?? 'assets/images/user-default.svg' }}" alt="プロフィール画像" class="user-icon-image is-small">
                    @if (item.owner?.identification_status_info?.process_status === 2) {
                      <img src="yellow-check-circle.png" alt="本人確認済み" class="user-verified-image">
                    }
                  </div>
                </div>
                <div class="property-comment__text">
                  <div>
                    @if (item.owner?.user?.agent_flg === "1") {
                      <span class="fw-bold">{{ item?.owner?.user?.nickname }}</span>
                      <span class="badge bg-info ms-2">エージェント</span>
                    }
                    @else {
                      <a [routerLink]="['/user', item?.owner?.user?.user_id]" class="fw-bold">{{ item?.owner?.user?.nickname }}</a>
                      @if (publishData?.result?.publish_info?.owner?.user?.user_id === item?.owner?.user?.user_id) {
                        <span class="badge bg-primary ms-2">オーナー</span>
                      }
                    }
                  </div>
                  <pre class="property-comment__text-pre mt-1 mb-0">{{ item?.contents }}</pre>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="fs-6 text-muted">{{ item.post_date | date: 'yyyy/M/d' }}</div>
                    <div>
                      @let comment_refusal_count = item?.refusal_count ?? 0;
                      @let comment_un_read_refusal_count = item?.un_read_refusal_count ?? 0;
                      @if (comment_refusal_count == 0) {
                        <button type="button" class="btn btn-outline-secondary property-comment__report-button" disabled>違反報告(0)</button>
                      }
                      @else {
                        <button
                          type="button"
                          class="btn btn-outline-danger property-comment__report-button"
                          [ngClass]="{'btn-outline-danger': comment_un_read_refusal_count > 0, 'btn-outline-secondary': comment_un_read_refusal_count == 0}"
                          (click)="openReportModal(item?.comment_id)"
                        >
                          違反報告({{ comment_refusal_count }})
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.property-comment -->
            }
          </div>

        </div>
      </div>
    </div>

  </div>
</div>


<div class="mt-3 pt-3">
  <div class="card">
    <div class="card-body p-4 pb-0">
      <h2 class="mb-0">オファー</h2>
      <div class="container-fluid px-0 mt-4">
        <div class="row">

          @for (item of offerListData; track $index) {
            <div class="col-4">
              <div class="property-offer" [ngClass]="{'is-accepted': item.deal_status === 3}">
                <div class="user-icon-wrapper">
                  <img src="{{ item.offer_user?.user?.avatar_image_url ?? 'assets/images/user-default.svg' }}" alt="プロフィール画像" class="user-icon-image">
                  @if (item.offer_user?.identification_status_info?.process_status == 2) {
                    <img src="yellow-check-circle.png" alt="本人確認済み" class="user-verified-image">
                  }
                </div>
                <div class="ps-4">
                  <a [routerLink]="['/user', item.offer_user?.user?.user_id]" class="d-block fw-bold">{{ item.offer_user?.user?.nickname }}</a>
                  <div class="fw-bold text-danger"><span class="fs-1 pe-1">{{ item.price | number }}</span>円</div>
                </div>

                @if (item.deal_status === 3) {
                  <div class="badge bg-warning text-black property-offer__badge">承諾済み</div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>


<div class="mt-5 d-flex justify-content-between">
  <div>
    <button type="button" class="btn btn-secondary" (click)="onBackPage()">前の画面へ戻る</button>
  </div>
  <div>
    <button type="button" class="btn btn-danger" (click)="onDelete()">物件を削除する</button>
  </div>
</div>
