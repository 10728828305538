import { Component, inject, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ReportConfirmModalComponent } from '../../../components/report-confirm-modal/report-confirm-modal.component';

import { ConfirmModalComponent } from '../../../components/common/confirm-modal/confirm-modal.component';
import { CompleteModalComponent } from '../../../components/common/complete-modal/complete-modal.component';
import { PublishInfoService } from '../../../services/publish-info.service';
import { CommentInfo, GetPublishInfoDetailResponse, OfferInfo } from '../../../lib/gen/openapi';
import { DatePipe, DecimalPipe, NgClass, Location } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ZipcodePipe } from '../../../utils/pipes/zip-code.pipe';

@Component({
  selector: 'app-publish-info-detail',
  standalone: true,
  imports: [DecimalPipe, DatePipe, RouterLink, NgClass, ZipcodePipe],
  templateUrl: './publish-info-detail.component.html',
  styleUrl: './publish-info-detail.component.scss',
})
export class PublishInfoDetailComponent {
  private publishInfoservice = inject(PublishInfoService);
  private modalService = inject(NgbModal);
  private location = inject(Location);
  private router = inject(Router);

  publishData: GetPublishInfoDetailResponse | null = null;
  commentListData?: CommentInfo[];
  offerListData?: OfferInfo[];

  @Input()
  set id(publishId: string) {
    this.loadData(parseInt(publishId));
  }

  loadData(publishId: number) {
    this.publishInfoservice.getDetail(publishId).then((res) => {
      this.publishData = res;
    });

    this.publishInfoservice.getCommentList(publishId).then((res) => {
      this.commentListData = res.result?.comments;
    });

    this.publishInfoservice.getOfferList(publishId).then((res) => {
      this.offerListData = res.result;
    });
  }

  async openReportModal(commentId?: number) {
    if (this.publishData?.result?.publish_info?.publish_id) {
      const modalRef = this.modalService.open(ReportConfirmModalComponent, {
        size: 'lg',
        centered: true,
        modalDialogClass: 'report-confirm-modal',
      });
      modalRef.componentInstance.publishId = this.publishData?.result?.publish_info?.publish_id;
      if (commentId) {
        modalRef.componentInstance.commentId = commentId;
      }
      modalRef.componentInstance.onLoadData();

      await modalRef.result;
      this.loadData(this.publishData?.result?.publish_info?.publish_id);
    }
  }

  openConfirmModal() {
    this.modalService.open(ConfirmModalComponent, {
      centered: true,
      modalDialogClass: 'common-confirm-modal',
    });
  }

  openCompleteModal() {
    this.modalService.open(CompleteModalComponent, {
      centered: true,
      modalDialogClass: 'common-complete-modal',
    });
  }

  /**
   * 前のページに戻る
   */
  onBackPage() {
    this.location.back();
  }

  /**
   * 物件を削除する
   */
  async onDelete() {
    const publishId = this.publishData?.result?.publish_info?.publish_id;

    // 確認モーダルを開く
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, {
      centered: true,
      modalDialogClass: 'common-confirm-modal',
    });
    confirmModalRef.componentInstance.message = [
      'この物件を削除します。よろしいですか？',
      '操作は取り消せません。',
    ];
    confirmModalRef.componentInstance.submitBtnText = '物件を削除する';

    const doDelete = await confirmModalRef.result;
    if (doDelete) {
      if (publishId) {
        // API呼び出し
        await this.publishInfoservice.deletePublishInfo(publishId);
        // 完了モーダルを開く
        const completeModalRef = this.modalService.open(CompleteModalComponent, {
          centered: true,
          backdrop: 'static',
          modalDialogClass: 'common-complete-modal',
        });
        completeModalRef.componentInstance.message = '物件を削除しました。';
        completeModalRef.componentInstance.closeBtnText = 'トップページへ戻る';
        await completeModalRef.result;
        this.router.navigate(['dashboard']);
      }
    }
  }
}
